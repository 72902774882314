import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { page } from "components/page";
import navigate from "lib/navigate";
import qs from "query-string";

const setOpenidSSOLogin = gql`
  mutation setOpenidSSOLogin($form: OpenidSSOLoginForm) {
    result: setOpenidSSOLogin(Input: $form) {
      success
      errorType
    }
  }
`;

@withI18next(["common"])
@page
class OpenidSSOLogin extends React.Component {
  constructor(props) {
    super(props);
    let alertHiddenString =
      props.appStore.globalConfig["jumperrwd.login.alertHidden"];
    let alertHidden = alertHiddenString === "1";
    this.state = {
      auth: props.readerStore.auth,
      alertHidden: alertHidden,
    };
  }

  componentDidMount() {
    this.openidSSOLogin(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.auth !== props.readerStore.auth) {
      this.setState({ auth: props.readerStore.auth });
      if (props.readerStore.auth) {
        navigate("/");
      } else {
        this.openidSSOLogin(props);
      }
    }
  }

  @autobind
  async openidSSOLogin(props) {
    let { search } = props;
    let params = { ...search };
    let { code = "", token = "", setAleph = "" } = params;
    let redirectURL = "https://hyerm.ntl.edu.tw/openidssologin";
    if(code === "") {
        let oauthParams = {
            client_id: "ec23b28135404fc8a1bf79b82906c31b",
            scope: "User.Mail,User.BasicInfo,User.Application,User.Identity,UserInfo,User.ContactInfo",
            state: "",
            redirect_uri: redirectURL,
            response_type: "code"
        };
        window.location.href = ["https://auth.ntpc.edu.tw/oauth/authorize.php", qs.stringify(oauthParams)].join("?");
    } else {
        client.jumperrwdClient
        .mutate({
          mutation: setOpenidSSOLogin,
          variables: {
            form: {
                code: code,
                token: token,
                setAleph: setAleph
            },
          },
        })
        .then(async (res) => {
          let { result } = res.data;
          if (result.success) {
            this.props.readerStore.syncSessionCookie().then(() => {
                navigate("/");
            });
          } else {
            if (result.ErrorType === 1) {
                this.props.readerStore.syncSessionCookie().then(() => {
                    navigate("/openidSSOLoginAleph");
                });
            } else {
                alert("登入失敗");
                navigate("/");
            }
          }
        });
    }
  }

  render() {
    let { t, isprivate, i18n } = this.props;
    return (
      <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
        <div
          ref={(c) => {
            this.container = c;
          }}
        />
      </Layout>
    );
  }
}

export default OpenidSSOLogin;
